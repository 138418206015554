import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import GalleryNav from "../../components/UI/GalleryNav"
import PhotoGallery from "../../components/PhotoGallery"
import CallToAction from "../../components/CallToAction"

// const query = graphql`
//   {
//     contentfulWaterfallsGallery {
//       images {
//         gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
//         title
//         id
//       }
//     }
//   }
// `

export default function PhotosPage() {
  // const {
  //   contentfulWaterfallsGallery: { images: imageList },
  // } = useStaticQuery(query)

  return (
    <Layout>
      <Navbar />
      <Seo
        title="Waterfalls | Photos"
        description="Our gallery of waterfall images show the natural style Andreatta Waterscapes specializes in creating which mimic the streams and waterfalls found in nature."
      />
      <GalleryNav />
      {/* <PhotoGallery
        title="Waterfalls"
        description="The immediate draw to almost every water feature we build is the casading sound of the waterfall as it tumbles over the rocks and splashes down into it's basin.  

This is the primary reason that most people want to have a water feature installed at their home... the sound of the water is the most sought after attribute our clients are looking for."
        imageList={imageList}
      /> */}
      <CallToAction />
    </Layout>
  )
}
